/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';
import ScrollspyNav from 'react-scrollspy-nav';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import '../../../dynamic-pages/hsr-character-dynamic.scss';
import './hsr-guides.scss';
import { Alert, Col, Row } from 'react-bootstrap';
import { HSRSkill } from '../../../modules/hsr/skills/hsr-skill';
import { HSRStat } from '../../../modules/hsr/common/components/hsr-stat';
import { HSRRelicSet } from '../../../modules/hsr/common/components/hsr-relic-set';
import { HSRLightCone } from '../../../modules/hsr/common/components/hsr-light-cone';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import {
  faBriefcaseMedical,
  faFlask,
  faHandFist,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProfileCastoriceDPS } from '../../../modules/hsr/profile/castorice-calcs';

const SRGuidesRappa: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Castorice - First Impressions</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_castorice.webp"
            alt="Castorice - First impressions"
          />
        </div>
        <div className="page-details">
          <h1>Castorice - First Impressions</h1>
          <h2>
            Check the early access first impressions of Castorice - a new
            character coming soon to Honkai: Star Rail!
          </h2>
          <p>
            Last updated: <strong>02/04/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      {/* <div className="page-content">
        <SectionHeader title="Disclaimer" />
        <p>Coming soon!</p>
      </div> */}
      <div className="page-content character-hsr-v2 special-guide ">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Disclaimer" />
              <Alert variant="primary alert-red">
                <p>
                  The information you can find in this article{' '}
                  <strong>
                    come from the Creator Experience server and as such are
                    subject to change
                  </strong>{' '}
                  - so while you can check out Castorice's kit details a bit
                  early, please keep that in mind. A full guide for Castorice
                  will be released as usual once the patch introducing her goes
                  live.
                </p>
              </Alert>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Introduction" />
              <StaticImage
                src="../../../images/starrail/robin/intro.webp"
                alt="Castorice"
              />
              <h6>Castorice's Profile</h6>
              <p>
                <strong className={`Quantum`}>Castorice</strong> is a{' '}
                <strong className={`rarity-5`}>5★</strong> character from the{' '}
                <strong className={`Quantum`}>Quantum</strong> element who
                follows the{' '}
                <strong className={`Remembrance`}>Path of Remembrance</strong>.
                The land that reveres death, Aidonia, where snow falls
                endlessly, has today drifted into a sweet slumber. Castorice,
                daughter of the River of Souls, Chrysos Heir in search of
                "Death" Coreflame, sets forth. You must guard the lament of
                souls and embrace the solitude of destiny.
              </p>
              <h6>Castorice's Intro</h6>
              <p>
                <strong>
                  The adorable yet deadly Reaper has arrived — and she does not
                  disappoint.
                </strong>{' '}
                Castorice is a powerful damage dealer that works well with F2P
                characters, making building a team dedicated for her effortless.
                This is especially valuable for newer players, as she performs
                exceptionally well without the need to heavily invest in limited
                characters. Even better, one of her{' '}
                <strong>
                  strongest supports is the Remembrance Trailblazer (the main
                  character), whom everyone gets for free.
                </strong>
              </p>
              <p>
                <strong>
                  Castorice’s playstyle is both unique and engaging
                </strong>
                . Being a character of the Path of Remembrance, she heavily
                relies on her summon - Netherwing (her huge dragon) - to deal
                damage. To summon the dragon, Castorice’s allies either need to
                lose HP (which is easy to do as Castorice drains it) or need to
                be healed (making healers very important for her - as they
                basically double as supports that fuel Castorice's ultimate) but
                preferably both. Once Netherwing is summoned, you might expect
                it to fight alongside her, and while that’s an option, the
                preferred strategy is to ‘detonate’ the dragon for massive AoE
                damage.{' '}
                <strong>
                  This creates the nice flowing playstyle of: draining and
                  healing allies HP, summoning the dragon, asking the dragon to
                  go boom, killing everything
                </strong>
                . Rinse and repeat. And to make things even better, the AI for
                auto battle actually plays Castorice this way, so if you're fan
                of the 'fun button', you won't be disappointed by the AI’s
                performance.
              </p>
              <p>
                So only one question remains: how does Castorice compare to
                other damage dealers?{' '}
                <strong>
                  Well, she's one of the strongest damage dealers in the game on
                  her release
                </strong>
                , all while her Best in Slot team features two free characters -
                Remembrance Trailblazer and Gallagher (4* Healer). This means
                she’s not just powerful from the start, but also has significant
                room for growth, making her a great investment for both new and
                veteran players alike.
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Kit" />
              <p>
                First, let's go over Castorice's kit - so stats, skills, major
                traces and minor traces - and explain how they work.
              </p>
              <h6>Castorice's Stats (level 80)</h6>
              <Row xs={1} xxl={4} className="va-section">
                <Col>
                  <div className="info-list-row">
                    <div className="category">HP</div>
                    <div className="details">1630</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">DEF</div>
                    <div className="details">485</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">ATK</div>
                    <div className="details">524</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Speed</div>
                    <div className="details">95</div>
                  </div>
                </Col>
              </Row>
              <h6>Castorice's Skills</h6>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140701"
                        maxLevel={7}
                        element="Quantum"
                        gen="0"
                        break_primary="30"
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                An unremarkable Basic ATK that scales on her HP. Mostly has no
                uses since her Skill does not consume any Skill Point - the only
                reason to be using this is if you need to avoid the HP
                consumption from her Skill.
              </p>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140702"
                        maxLevel={12}
                        element="Quantum"
                        gen=""
                        break_primary="60"
                        break_secondary="30"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140708"
                        maxLevel={12}
                        element="Quantum"
                        gen=""
                        break_primary="60"
                        break_secondary=""
                        isEnhanced={true}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Castorice's skill does not consume any Skill Points, and will
                consume 30% HP from all allies (including memosprites). For this
                reason, pairing her with Remembrance characters is especially
                useful for her Ultimate Charge generation, and this also
                incentivizes higher HP allies.
              </p>
              <p>
                While Netherwing is on the field, her Skill becomes enhanced,
                launching 2 attacks to deal 80% of Castorice’s HP as DMG in
                total. This Skill will consume 40% of all allies’ HP and heal
                Netherwing by an equal amount - essentially as an offering.
              </p>
              <p>
                Also, keep in mind that while the normal version of the skill is
                Blast (so it attacks up to 3 enemies), the enhanced version is
                full AoE (up to 5 targets).
              </p>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140703"
                        maxLevel={12}
                        element="Quantum"
                        gen=""
                        break_primary=""
                        break_secondary=""
                        ult_cost={0}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Her Ultimate will summon Netherwing (and allow it to act right
                after) and also create a special Territory "Lost Netherland"
                while Netherwing remains active. While the Territory is active,
                it decreases all enemies’ All-Type RES by 20%. Netherwing
                usually doesn’t stick around long enough for this bonus to apply
                to allies but in the event they, do it’s a tremendous boost to
                their (Netherwing) and the team's damage. Note: even if you
                instantly detonate Netherwing the 20% boost will still apply to
                those attacks.
              </p>
              <p>
                Furthermore, as long as Netherwing is alive, Castorice can't
                accumulate any Ultimate Charges, and excess healing is instead
                converted to Netherwing’s HP (this effect comes from her
                Talent).
              </p>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140704"
                        maxLevel={12}
                        element="Quantum"
                        gen=""
                        break_primary=""
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Castorice’s talent is very important and defines her whole kit.
              </p>
              <p>
                Castorice gains Ultimate Charge based on healing AND HP loss by
                allies. At max equilibrium level, the amount of “Newbud”
                required to use her Ultimate is set at <strong>34,000</strong> -
                and this will also be her memosprite’s max HP.
              </p>
              <p>
                Keep in mind that both DMG dealt by Castorice and Netherwing
                scales purely on Castorice’s HP and not from Netherwing.
                Netherwing’s HP only exists as a threshold for healing/HP loss
                required, or to absorb fatal DMG from allies via Memosprite
                Talent.
              </p>
              <p>
                The last element of the Talent is a damage increase to both
                Castorice and Netherwing whenever allies lose HP (20% per stack,
                up to 3 stacks).
              </p>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140707"
                        maxLevel={1}
                        element="Quantum"
                        gen=""
                        break_primary=""
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                The Technique summons Netherwing at the start of the battle, but
                it's not as powerful as the Ultimate. As Netherwing starts at
                50% HP + whatever they 'siphons' from the allies (usually it
                puts them at 70-80% max HP).
              </p>
              <p>
                Make sure to attack the enemy with Castorice after she uses her
                Technique and not switch to another character - else the
                Technique won't activate.
              </p>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="140706"
                        maxLevel={1}
                        element="Quantum"
                        gen=""
                        break_primary=""
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Castorice is the first character with a unique, global passive.
                As long as you have Castorice on your account, she will prevent
                the death of her allies - keeping them at 1 HP and as long as
                they are healed or shielded within a turn, they won't die. This
                effect can only activate once per battle.
              </p>
              <h6>Castorice's Memesprite Skills</h6>
              <p>
                During Netherwing’s turns, Castorice can choose between two
                Memosprite Skills - either a “claw attack” or the “breath
                attack”.
              </p>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id={'1407010'}
                        maxLevel={7}
                        element="Quantum"
                        gen={''}
                        break_primary={'30'}
                        break_secondary={''}
                        disabled={false}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Deals AoE damage based on Castorice’s HP and ends the current
                action. This skill is mainly used to delay Netherwing’s
                detonation and exit from the field, in order to line up your
                team's buffs on them before executing a full set of breaths thus
                triggering a detonation (very important if you play Castorice
                with Sunday).
              </p>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id={'1407011'}
                        maxLevel={7}
                        element="Quantum"
                        gen={''}
                        break_primary={'30'}
                        break_secondary={''}
                        disabled={false}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Consumes 25% of Netherwing’s Max HP and deals AoE damage to all
                enemies. Using this skill won't conclude the current turn, and
                it can be used indefinitely as long as Netherwing has sufficient
                HP. Using this Skill repeatedly also increases the DMG
                multiplier of the ability, lasting until Netherwing disappears.
                When using this ability at 25% HP or lower, Netherwing will
                detonate and exit the field immediately after.
              </p>
              <p>
                At a baseline when Netherwing’s HP is at max, this ability can
                be used 4 times - consuming 100% of Max HP and immediately
                triggering the exit move. But the thresholds are pretty liberal
                - for example, if Netherwing sits at 80% before you use the
                skill, you will still be able to use it 4 times as you will have
                5% 'left' for the 4th breath. Only when you're at 75% HP or
                lower, the number of breaths you can do will be decreased.
              </p>
              <p>
                Also, the increasing damage multiplier from this skill stacks
                with the DMG% buff from the Major Talent. So using as many
                breaths as possible in 'one go' is generally preferred.
              </p>
              <h6>Castorice's Memesprite Talents</h6>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id={'1407012'}
                        maxLevel={1}
                        element="Quantum"
                        gen={''}
                        break_primary={''}
                        break_secondary={''}
                        disabled={false}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Netherwing can absorb fatal DMG from allies at a 5:1 ratio,
                serving as a second line of protection, especially considering
                the fact that Castorice’s Skills consume a significant portion
                of HP from allies, sometimes leaving them low. Although this is
                useful it will drain a significant amount of HP from Netherwing.
              </p>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id={'1407013'}
                        maxLevel={1}
                        element="Quantum"
                        gen={''}
                        break_primary={''}
                        break_secondary={''}
                        disabled={false}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                A minor 10% DMG Boost for all allies including Netherwing - just
                a nice bonus for when you pair her with multiple damaging units
                such as Mydei and Tribbie.
              </p>
              <div className={`skills Quantum`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id={'1407014'}
                        maxLevel={7}
                        element="Quantum"
                        gen={''}
                        break_primary={'15'}
                        break_secondary={''}
                        disabled={false}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                When Netherwing exits the field, they deal 6 instances of Bounce
                DMG with each hit dealing DMG equal to 40% of Castorice’s Max
                HP. As a bounce attack, it is extremely effective when fighting
                against bosses, but loses value in AoE scenarios.
              </p>
              <p>
                Since this ability does deal a significant portion of DMG, it is
                better to detonate the dragon from 100% to accumulate more DMG
                Boost stacks from her Ascension 6 Bonus Ability. Also, as a nice
                bonus, it will heal all allies by some amount, but unfortunately
                this does not count towards her next Ultimate.
              </p>
              <h6>Castorice's Major Traces</h6>
              <div className={`skills traces Quantum`}>
                <Row xs={1} xl={2} xxl={3}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Quantum`}>
                          A2
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Contained Dark Tide</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Quantum`}>
                        After ally targets (excluding Netherwing) receive
                        healing, converts <b>100%</b> of healed value into
                        "Newbud." If Netherwing is on the battlefield, the
                        healing is instead converted into Netherwing's HP. The
                        converted value for each ally target cannot exceed{' '}
                        <b>12%</b> of "Newbud"'s maximum limit. The accumulated
                        conversion value from all units resets after any unit
                        takes action.
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Quantum`}>
                          A4
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Inverted Torch</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Quantum`}>
                        When Castorice's current HP is greater or equal to 50%
                        of this unit's Max HP, her SPD increases by <b>40%</b>.
                        When Netherwing uses "Breath Scorches the Shadow" and
                        deals fatal damage to all enemies on the field or brings
                        them to a point where they cannot lose more HP,
                        Netherwing's SPD increases by <b>100%</b> for <b>1</b>{' '}
                        turn.
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Quantum`}>
                          A6
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Where The West Wind Dwells
                          </p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Quantum`}>
                        Each time Netherwing uses "Breath Scorches the Shadow,"
                        increases its DMG dealt by <b>30%</b>. This effect
                        stacks up to 6 and lasts until the end of this turn.
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Her Bonus Abilities are extremely useful and here's what they
                do:
              </p>
              <ul>
                <li>
                  <strong>A2</strong> is essential to Castorice's functionality,
                  as it allows healing to also charge her Ultimate or to be
                  converted to Netherwing’s HP. The healing limit is per target,
                  and per action for a maximum of 4,080. So for example, someone
                  like Gallagher healing a single ally for 7.5k will be affected
                  by the cap, while for Luocha’s Zone healing, it is spread
                  amongst multiple allies and does not reach the cap so easily.
                </li>
                <li>
                  <strong>A4</strong> is a nice QOL that makes running her with
                  single-target buffers a bit easier. If Castorice is above 50%
                  HP, her SPD is increased by 40% - this is a combat buff so it
                  will not affect the 4-Pc Poet of Mourning Collapse’s
                  activation condition, allowing her to reach around 125-133 SPD
                  in combat. The second portion increases Netherwing’s SPD by
                  100% if it cannot do anymore DMG during its turn either from
                  all enemies dying or due to enemy Bosses’ phase change. This
                  effect is pretty useful in Pure Fiction.
                </li>
                <li>
                  <strong>A6</strong> is a very powerful DMG buff that
                  encourages Netherwing to save up HP to use all of it in 1
                  turn, which will provide a ramping DMG% buff up to 120%
                  practically at E0, which also affects the DMG dealt by her
                  Memosprite Talent’s finisher move.
                </li>
              </ul>
              <h6>Castorice's Minor Traces</h6>
              <div className="smaller-traces">
                <Row xs={1} xxl={3}>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="CRIT Rate" />{' '}
                      <span className="value">+18.7%</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="CRIT DMG" />{' '}
                      <span className="value">+13.3%</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="Quantum DMG" />{' '}
                      <span className="value">+14.4%</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Eidolons" />
              <p>
                Now let's check Castorice's Eidolons and how they improve her
                performance.
              </p>
              <h6>Castorice's Eidolons</h6>
              <div className="skills eidolons">
                <Row xs={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Quantum`}>E1</div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Snowbound Maiden, Memory to Tomb
                          </p>
                          <p className="skill-type">Eidolon 1</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Quantum`}>
                        <p>
                          When the enemy target's current HP is <b>80%/50%</b>{' '}
                          or lower than this unit's Max HP, the DMG dealt with
                          "Boneclaw, Doomdrake's Embrace," "Claw Splits the
                          Veil," "Breath Scorches the Shadow," and "Wings Sweep
                          the Ruins" is <b>120%/140%</b> of the original DMG.{' '}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Quantum`}>E2</div>
                        <div className="skill-info">
                          <p className="skill-name">Crown on Wings of Bloom</p>
                          <p className="skill-type">Eidolon 2</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Quantum`}>
                        <p>
                          After summoning memosprite Netherwing, Castorice gains
                          <b>2</b> stack(s) of "Ardent Will." A maximum of 2
                          stacks of "Ardent Will" can be possessed at any given
                          time, and can be used to offset Netherwing's HP
                          consumption when using Memosprite Skill "Breath
                          Scorches the Shadow" while advancing Castorice's
                          action by <b>100%</b>. In the next usage of Enhanced
                          Skill, Castorice gains <b>30%</b> of maximum "Newbud"
                          points.{' '}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Quantum`}>E4</div>
                        <div className="skill-info">
                          <p className="skill-name">Rest in Songs of Gloom</p>
                          <p className="skill-type">Eidolon 4</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Quantum`}>
                        <p>
                          While Castorice is on the battlefield, all allies'
                          Incoming Healing increases by <b>20%</b> when having
                          their HP restored.{' '}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Quantum`}>E6</div>
                        <div className="skill-info">
                          <p className="skill-name">Await for Years to Loom</p>
                          <p className="skill-type">Eidolon 6</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Quantum`}>
                        <p>
                          When Castorice or Netherwing deals DMG, increases
                          Quantum RES PEN by <b>20%</b>. When Netherwing
                          attacks, it can ignore Weakness Type and directly
                          reduce enemy Toughness. When it inflicts Weakness
                          Break, triggers the Quantum Weakness Break effect.
                          Additionally increases the Bounce count for
                          Netherwing's Talent "Wings Sweep the Ruins" by{' '}
                          <b>3</b> time(s).{' '}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <h6>Eidolons reviewed:</h6>
              <ul>
                <li>
                  <strong>E1</strong> provides an independent DMG multiplier
                  against lower HP enemies. While it is a significant boost, the
                  full effect only applies to targets below 50% HP.
                </li>
                <li>
                  <strong>E2</strong> can be immensely powerful, granting
                  Castorice 30% faster access to Netherwing, 2 extra breaths
                  (leading to more damage and more DMG% buff stacks), and action
                  advancement that allows her to use her Joint attacks
                  incredibly frequently. All of this adds up to an absurdly
                  powerful boost, but this boost comes with a catch. The
                  advancement from this Eidolon can severely interfere with
                  certain support characters buff uptimes on Castorice,
                  resulting in much lower gains in reality than in theory - this
                  can be played around but is worth keeping in mind when
                  assessing the power of this Eidolon.
                </li>
                <li>
                  <strong>E4</strong> is a rather nice 20% healing efficiency
                  increase to help with Ultimate Charge.
                </li>
                <li>
                  <strong>E6</strong> grants her another 20% RES PEN on top of
                  her Ultimate’s 20% RES reduction, and enables Netherwing to
                  ignore Weakness Types, helping her deal with enemies without
                  matching Weakness. The Weakness Type ignore mostly does not
                  matter much other than in Apocalyptic Shadow. The Eidolon also
                  increases her Bounce count by 3, now dealing 9 hits for a
                  total of 450% of her HP.
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Relics and Light Cones" />
              <div className={`section-build Quantum `}>
                <div className="build-stats">
                  <h6 className="with-margin">Recommended Main and Substats</h6>
                  <Row xs={1} xl={2} xxl={2} className="main-stats">
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Body</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="CRIT DMG" />{' '}
                          <span className="order">&gt;</span>{' '}
                          <HSRStat stat="HP" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Feet</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="HP%" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Planar Sphere</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="HP%" />{' '}
                          <span className="order">&gt;</span>{' '}
                          <HSRStat stat="Quantum DMG" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Link Rope</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="HP%" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="box sub-stats">
                        <span>Substats:</span>
                        <p>
                          CRIT DMG = CRIT Rate &gt; HP% &gt; SPD (Less than 95)
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <h6>Recommended Endgame Stats (while buffed in combat)</h6>
                <ul>
                  <li>
                    HP: <strong>7000-8000+</strong>
                  </li>
                  <li>
                    DEF: <strong>700+</strong>
                  </li>
                  <li>
                    CRIT Rate: <strong>80-100%</strong> (Including Poet)
                  </li>
                  <li>
                    CRIT DMG: <strong>200-220%</strong>+
                  </li>
                  <li>
                    SPD: <strong>Base Speed OR &lt;95</strong>
                  </li>
                </ul>
                <hr />
                <p>More info about the stats:</p>
                <ul>
                  <li>
                    <strong>ATK</strong>: As an HP scaler Castorice has zero use
                    for ATK, avoid building it entirely.
                  </li>
                  <li>
                    <strong>SPD</strong>: Castorice's best relic set (by a very
                    significant margin) requires she remain below 95 Speed in
                    order to fully activate. This combined with the fact that
                    she gains a 40% increased Speed while above 50% HP, makes
                    SPD a lacklustre stat to build on her unless aiming to hit
                    specific speed tuning break points (like when playing with
                    Sunday).
                  </li>
                  <li>
                    <strong>OFFENSIVE STATS</strong>: When it comes to building
                    offensive stats on Castorice CRIT DMG and CRIT RATE are the
                    priority, but because Castorice gains so much CRIT RATE from
                    top her relic set, best teammates and traces, it's very
                    possible you'll hit 100% or even overcap your CRIT RATE if
                    you're not careful.
                  </li>
                  <ul>
                    <li>
                      Due to this CRIT DMG is generally preferrable on gear.
                      After capping out CRIT RATE and getting as much CRIT DMG
                      as possible, HP% is the next most valuable stat to build
                      when compared to the remaning stat of DMG%.
                    </li>
                    <li>
                      The reason for this is simple - Castorice deals most of
                      her damage via her Memosprite and that Memosprites gains
                      an absurd amount of DMG% for free. The exception to all
                      these rules is if you're able to gain damage
                      amplifications outside of the norm (such as TRUE DMG%, DEF
                      SHRED%, RES PEN% and DMG TAKEN%) these all hold a higher
                      priority over the more accessible stats found on relics.
                    </li>
                  </ul>
                </ul>
                <div className="build-relics">
                  <h6>Recommended Relic Sets</h6>
                  <p>
                    Keep in mind that majority of Castorice's damage comes from
                    Netherwing, most set effects don't really work or do
                    anything to benefit her.
                  </p>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone Quantum`}>
                      <div className={`percentage default`}>
                        <p>100%</p>
                      </div>
                      <HSRRelicSet
                        name="Poet of Mourning Collapse"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Castorice can naturally be played on the slower side due
                        to her kit and free 40% Speed boost in combat, which
                        doesn't interfere with the 4P set bonus. All this makes
                        this set almost purely upside and the highest priority
                        to farm if you aren't using it yet.
                      </p>
                    </div>
                    <div className={`single-cone with-notes Quantum`}>
                      <div className={`percentage`}>
                        <p>87.75%</p>
                      </div>
                      <div className="double-set">
                        <HSRRelicSet
                          name="Scholar Lost in Erudition"
                          compactMode
                          comment="2PC"
                        />
                        <>
                          <div className="flex-placeholder">Flex</div>
                        </>
                      </div>
                    </div>
                    <div className={`information Quantum`}>
                      <p>
                        Using a 2P/2P combo on Castorice is a significant
                        downgrade over her best set - only do so while you're in
                        the process of farming for her best in slot 4P Poet of
                        Mourning.
                      </p>
                      <p>
                        If you can't put together a top recommended 4P set with
                        satisfactory sub/main stats, mix and match the 2P
                        Bonuses from the following instead:
                      </p>
                      <ul className="with-sets">
                        <li>
                          <HSRRelicSet
                            name="Longevous Disciple"
                            compactMode
                            smol
                          />{' '}
                          <span className="value">[HP +12%]</span>
                        </li>
                        <li>
                          <HSRRelicSet
                            name="Genius of Brilliant Stars"
                            compactMode
                            smol
                          />{' '}
                          <span className="value">[Quantum DMG +10%]</span>
                        </li>
                        <li>
                          <HSRRelicSet
                            name="Poet of Mourning Collapse"
                            compactMode
                            smol
                          />{' '}
                          <span className="value">[Quantum DMG +10%]</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h6 className="with-margin">Recommended Planetary Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone with-notes Quantum`}>
                      <div className={`percentage`}>
                        <p>100%</p>
                      </div>
                      <HSRRelicSet
                        name="Bone Collection's Serene Demesne"
                        compactMode
                      />
                    </div>
                    <div className={`information Quantum`}>
                      <p>
                        The natural choice for Castorice as most other options
                        either don't affect her memosprite or have unsuitable
                        stats.
                      </p>
                    </div>
                    <div className={`single-cone with-notes Quantum`}>
                      <div className={`percentage`}>
                        <p>92.7%</p>
                      </div>
                      <HSRRelicSet
                        name="The Wondrous BananAmusement Park"
                        compactMode
                      />
                    </div>
                    <div className={`single-cone with-notes Quantum`}>
                      <div className={`percentage`}>
                        <p>92.09%</p>
                      </div>
                      <HSRRelicSet name="Fleet of the Ageless" compactMode />
                    </div>
                    <div className={`single-cone with-notes Quantum`}>
                      <div className={`percentage`}>
                        <p>92.07%</p>
                      </div>
                      <HSRRelicSet name="Rutilant Arena" compactMode />
                    </div>
                    <div className={`single-cone with-notes Quantum`}>
                      <div className={`percentage`}>
                        <p>91.00%</p>
                      </div>
                      <HSRRelicSet name="Inert Salsotto" compactMode />
                    </div>
                    <div className={`information Quantum`}>
                      <p>
                        All the above options have their own issues - mainly not
                        affecting Netherwing - so only use them when you're
                        farming the Bone Collection set.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="build-cones">
                  <h6>Recommended Light Cones</h6>
                  <>
                    <div className="detailed-cones moc">
                      <div className={`single-cone Quantum`}>
                        <div className="percentage">
                          <p>123.10%</p>
                        </div>
                        <HSRLightCone
                          slug="make-farewells-more-beautiful"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Due to the Remembrance path limited Light Cone
                          options, her signature is a large improvement over her
                          other options. Notably the Action Advance from this
                          Light Cone can allow you to hit AV breakpoints such as
                          the first cycle in Memory of Chaos - since her
                          in-combat SPD with 4-Pc Poet’s upper limit is ~133,
                          and it can also enable her to overtake 135 SPD Sunday
                          for the -1 SPD Sync. This Light Cone also
                          significantly increases her Max HP, allowing for more
                          HP to be consumed for her Ultimate Charges and has a
                          whopping 30% DEF SHRED - a stat she doesn't have easy
                          access to elsewhere and which stacks incredibly well
                          with other DEF shredding sources.
                        </p>
                      </div>
                      <div className={`single-cone Quantum`}>
                        <div className="percentage">
                          <p>107.48%</p>
                        </div>
                        <HSRLightCone
                          slug="sweat-now-cry-less"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          A strong alternative to Castorice's signature when at
                          S5 but with the downside of granting CRIT Rate that
                          can easily overflow beyond 100% due to her Traces,
                          Relics, and team buffs if not careful, wasting a
                          portion of its potential.
                        </p>
                      </div>
                      <div className={`single-cone Quantum`}>
                        <div className="percentage">
                          <p>100%</p>
                        </div>
                        <HSRLightCone
                          slug="sweat-now-cry-less"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Noticably weaker at S1 than at S5, but still a
                          reasonable option for Castorice - as long as the CRIT
                          RATE isn't wasted.
                        </p>
                      </div>
                      <div className={`single-cone Quantum`}>
                        <div className="percentage">
                          <p>93.23%</p>
                        </div>
                        <HSRLightCone
                          slug="time-waits-for-no-one"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Due to limited Cone options, and due to how much HP%
                          can stack via Relics and stats, running Light Cones
                          with high Base HP funnily enough can be competitive,
                          especially because Castorice doesn’t need many DMG
                          Bonuses thanks to her kit being stuffed with them.
                          Cones with 1270 Base HP or higher are reasonable
                          options (regardless of path).
                        </p>
                      </div>
                      <div className={`single-cone Quantum`}>
                        <div className="percentage">
                          <p>87.08%</p>
                        </div>
                        <HSRLightCone
                          slug="post-op-conversation"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Like above, Light Cones with 1058 HP can also work
                          well and outpeform other Rememberance options.
                        </p>
                      </div>
                      <div className={`single-cone Quantum`}>
                        <div className="percentage">
                          <p>86.80%</p>
                        </div>
                        <HSRLightCone
                          slug="victory-in-a-blink"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Unfortunately this Cone loses out to Base HP off-path
                          Cone due to its low base HP, mediocre CRIT DMG boost,
                          and the fact its DMG% does not apply for Castorice.
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Signature vs Eidolons" />
              <ProfileCastoriceDPS />
              <hr />
              <h5>What is more important - the Signature or Eidolons?</h5>
              <p>
                If you compare E1 against the Signature (S1), the Signature wins
                by a big margin - not only it provides a lot HP, DEF Shred, but
                also some QOL with the action advance. Other Light Cones simply
                are much worse.
              </p>
              <p>
                And even if you're planning to go for E2 that is a massive
                upgrade (but with some downsides), you still would want to get
                the Signature on top of that.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Synergy" />
              <ul className="bigger-margin">
                <li>
                  <HSRCharacter
                    mode="inline"
                    slug="trailblazer-remembrance"
                    enablePopover
                  />{' '}
                  - As a Remembrance character, the Trailblazer has their
                  memosprite Mem, which gives Castorice more targets to consume
                  HP from. Once Mem’s Support is on Castorice, Netherwing will
                  also receive it as well when summoned, allowing both to deal a
                  tremendous 36% extra True DMG. On top of this, Trailblazer
                  also brings 10% CRIT RATE and a substanial amount of CRIT DMG
                  to the party while also directly benefitting from Castorice
                  and Netherwing as well (The Trailblazer’s E2 regenerates
                  Energy for themself when Netherwing takes action). At the time
                  of Castorice's release Rememberance Trailblazer is one of the
                  best supports for her.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="tribbie" enablePopover /> -
                  Tribbie is the single best support for Castorice, so much so
                  that teams with and without her present will have a noticable
                  gap in performance in all endgame modes (especially in Pure
                  Fiction). There are many reasons Tribbie is such a powerful
                  teammate for Castorice.
                </li>
                <ul>
                  <li>
                    Tribbie can easily reach higher HP pools than most
                    characters, thanks to her HP stacking nature and unique HP
                    boosting bonus ability.{' '}
                  </li>
                  <li>
                    Her universal and rare buffs are extremely beneficial for
                    Castorice, who cannot benefit from ATK and already receives
                    massive HP%, DMG%, and CRIT stats from her kit and Relics.{' '}
                  </li>
                  <li>
                    To top it all, Tribbie's personal damage is great and she's
                    also able to assist with triggering the effects of the two
                    best healers for Castorice, thanks to her frequent follow-up
                    attacks, allowing them to heal more often and as a result
                    grant Castorice more points toward her Ultimate.
                  </li>
                </ul>
                <li>
                  <HSRCharacter mode="inline" slug="sunday" enablePopover /> -
                  Sunday is another effective supportive option for Castorice,
                  thanks to his summon-themed advancement effect and generic
                  amplifications. With that said, he does come with a few
                  inconveniences to work around compared to other options.
                  Castorice does not use Energy, making one of Sunday's biggest
                  selling points wasted. Also when Netherwing is summoned, it
                  does not retroactively inherit Sunday’s Skill, Ultimate and
                  Talent buffs from Castorice, meaning you'll have to do without
                  them or keep Netherwing active long enough for them to gain
                  them when you buff Castorice. In addition to this, Castorice’s
                  SPD can fluctuate due to her 40% Speed bonus ability, leading
                  to sometimes unreliable SPD tuning. On top of this,
                  Netherwing’s SPD is set to 165 - a Speed that is very much not
                  attainable for Castorice while using her top set of 4P Poet
                  (and it's relevant if you plan to keep them active). Finally,
                  if you have Castorice's Signature or E2, both of these have
                  action advances which can help or harm your Speed tuning with
                  Sunday. All of this adds up to quite a lot to manage when
                  choosing a Speed breakpoint and playing around it. Here are a
                  few speed breakpoints and tips to consider when playing the
                  two together:
                </li>
                <ul>
                  <li>
                    To grant Castorice double actions in combat, speed tune
                    Sunday to be 1 Speed slower than her in combat after her 40%
                    Speed boost is factored in. If you're worried she'll dip
                    below 50% HP at certain points in combat, you can set
                    Sunday's Speed to be slower than hers (the slower the
                    safer).
                  </li>
                  <li>
                    If you have Castorice's signature Cone, you can use the
                    above strategy, but while building Sunday faster - because
                    Castorice is able to reliably action advance herself,
                    pushing her up in the action order whenever detonating
                    Netherwing. You can use this to serve as a cover for when
                    her HP gets low and she dips below 50% HP or you can even
                    opt to build Sunday faster than Castorice with her buff
                    active if you feel you can reliably detonate Netherwing
                    every time she and Sunday would move. For example, 134 Speed
                    Sunday and enough Speed on Castorice that she moves above
                    him after the dragon detonates via the action advance.
                  </li>
                  <li>
                    There is also Sunday builds that opt to be much faster and
                    focus on granting his Speed to Castorice by proxy while also
                    being Speed tuned to Netherwing in the event you choose to
                    keep them on the field to inherit Sunday's buffs. For
                    example 164 Sunday (1 Speed slower than Netherwing).
                  </li>
                  <li>
                    Regardless of what speed you choose when pairing both of
                    them together, be sure to always save Sunday’s Ultimate
                    until Netherwing is summoned, and try to wait for Sunday’s
                    Skill buff before consuming all of its HP, to ensure that
                    Netherwing receives as many of Sunday’s buffs as possible.
                  </li>
                </ul>
                <li>
                  <HSRCharacter mode="inline" slug="gallagher" enablePopover />{' '}
                  - Currently, Gallagher has the highest healing output for
                  Castorice but is reliant on enemy count and debuff uptime in
                  order to perform. This means he's strong when against a large
                  amount of enemies that stick around, but is noticably weaker
                  against frail or fast enemies (such as those in Pure Fiction).
                  His Ultimate’s Besotted State heals when allies attack,
                  granting them HP for every target they hit with his with the
                  debuff applied, this allows every single attack on the team to
                  proc healing thus granting Castorice a tonne of Ultimate
                  Charge in the process. Outside of this, Gallagher also has
                  very competant healing himself, cementing himself as
                  Castorices top choice at the time of her release in most
                  modes.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="luocha" enablePopover /> -
                  Luocha, like Gallagher, comes with on hit healing capabilites
                  in the form of his healing field - the difference is his
                  reliance on himself instead of of enemies in order to
                  function. This gives Luocha a distinct advantage in modes
                  where Gallagher is unable to maintain his ultimate debuff on
                  enemies. Luocha also isn't reliant on how many enemies are on
                  the field like Gallagher is. With all that said Luocha's kit
                  generally falls a little behind Gallagher's (in MoC and AS)
                  for Castorice's Ultimate Gauge generation. When playing Luocha
                  with Castorice focus on maximising his healing output by
                  stacking ATK% and Healing Output% where you can.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="mydei" enablePopover /> -
                  Mydei can also be paired with Castorice in a dual DPS setup
                  since they can benefit from each other. Castorice's massive HP
                  drain helps charge up Mydei's "Godslayer Be God" attacks, and
                  she can also provide a 20% All-Type RES reduction (provided
                  that Netherwing is maintained on the field) and she increases
                  DMG dealt by all allies by 10% when Netherwing is summoned.
                  Mydei in return takes more DMG than usual due to his 0 DEF and
                  has self-HP drain that also charge Castorice's Ultimate.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-7">
              <SectionHeader title="Playstyles & Teams" />
              <h5>Playstyles</h5>
              <p>
                Currently, there are 2 main ways you can pilot Netherwing,
                either instantly detonating it, or to keep it up as long as
                possible. Keep in mind that autoplay will always immediately
                detonate Netherwing - which is actually the preferred playstyle
                for most teams (not always for Sunday) even on manual.
              </p>
              <h6>Instant Detonation Playstyle</h6>
              <p>
                The first way to play Castorice is to immediately spam the
                breath attack right after Netherwing is summoned, consuming all
                of Netherwing’s HP to trigger the Bounce attack. The reason to
                do this is that her finisher attack is extremely potent and
                triggering it as many times as possible is key to maximising
                damage (especially in single target).
              </p>
              <p>
                The other reason behind instant detonation is to take full
                advantage of her A6 in order to ensure the explosion has as many
                stacks of her 30% buff as possible! Also with the Remembrance
                Trailblazer in the team, as long as Castorice has Mem’s Support,
                Netherwing will inherit the buff when it is summoned with no
                buff uptime issues. With Sunday however, it is recommended to
                wait until Netherwing is buffed before consuming all HP.
              </p>
              <div className="section-analysis">
                <Row className="pros-cons">
                  <Col sm="12" md="6">
                    <div className="box pros">
                      <h5>Pros</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>Most suitable for Hypercarry Castorice,</li>
                          <li>
                            No healing/HP loss wasted - all gets converted into
                            Ultimate Charge,
                          </li>
                          <li>
                            Strong Single-Target DMG from Memosprite Talent, and
                            ensures maximum DMG Boost stacks while triggering
                            it,
                          </li>
                          <li>
                            Triggers the summon/exit more often for the Action
                            Advance from signature Light Cone or E2.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="box cons">
                      <h5>Cons</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>
                            Significant pressure on healing/HP loss - Netherwing
                            can be inactive for a long time if there is not
                            enough healing to resummon it back up,
                          </li>
                          <li>
                            Team won't have access to Netherwing’s 20% RES PEN
                            territory effect consistently.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <h6>Maintaining Netherwing Playstyle</h6>
              <p>
                To keep Netherwing on the field, you can avoid using the Breath
                attack when it's at 25% HP or lower by using the Claw attack -
                generally you can do 3 Breath + Claw when Netherwing is at 100%
                HP.
              </p>
              <div className="section-analysis">
                <Row className="pros-cons">
                  <Col sm="12" md="6">
                    <div className="box pros">
                      <h5>Pros</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>
                            20% All-Type RES reduction debuff, so allies benefit
                            from it,
                          </li>
                          <li>
                            Lower pressure on Ultimate Charge, since Netherwing
                            will stay around for 3 turns.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="box cons">
                      <h5>Cons</h5>
                      <hr />
                      <div className="raw list">
                        <ul>
                          <li>
                            While not strictly required, you still need potent
                            healing to ensure Netherwing can perform 3 Breath
                            attacks every turn.
                          </li>
                          <li>
                            Her powerful finisher attack does not trigger as
                            often, resulting in weak single-target performance.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <h5>Teams</h5>
              <div className={`character-teams box Quantum`}>
                <div className="team-header">
                  <p>Best Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="castorice"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="tribbie"
                          enablePopover
                        />
                      </div>
                      <div className="role specialist">
                        <FontAwesomeIcon icon={faVirus} width="18" /> Support
                        DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter
                          mode="icon"
                          slug="trailblazer-remembrance"
                          enablePopover
                        />
                        <HSRCharacter mode="icon" slug="sunday" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                        <HSRCharacter mode="icon" slug="luocha" enablePopover />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Tribbie-less Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="castorice"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="ruan-mei"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter
                          mode="icon"
                          slug="trailblazer-remembrance"
                          enablePopover
                        />
                        <HSRCharacter mode="icon" slug="sunday" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                        <HSRCharacter mode="icon" slug="luocha" enablePopover />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>MyRice Team (Dual DPS)</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="castorice"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="mydei" enablePopover />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="tribbie"
                          enablePopover
                        />
                      </div>
                      <div className="role specialist">
                        <FontAwesomeIcon icon={faVirus} width="18" /> Support
                        DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character multi-equal small">
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                        <HSRCharacter mode="icon" slug="luocha" enablePopover />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>F2P Team</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="castorice"
                          enablePopover
                        />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="pela" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="trailblazer-remembrance"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-8">
              <SectionHeader title="What's Next?" />
              <p>
                That's the end of the early impressions article for Castorice
                and we hope you did enjoy it! As we mentioned in the
                introduction section,{' '}
                <strong>
                  full Castorice guide will be released and placed on her
                  profile once she's properly available in the game
                </strong>{' '}
                and then she will be also added to our tier list.
              </p>
              <p>Till then!</p>
              <StaticImage
                src="../../../images/starrail/robin/outro.webp"
                alt="Castorice"
              />
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6',
                'section-7',
                'section-8'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Disclaimer</a>
                </li>
                <li>
                  <a href="#section-1">Introduction</a>
                </li>
                <li>
                  <a href="#section-2">Kit</a>
                </li>
                <li>
                  <a href="#section-3">Eidolons</a>
                </li>
                <li>
                  <a href="#section-4">Relics & Light Cones</a>
                </li>
                <li>
                  <a href="#section-5">Signature vs Eidolons</a>
                </li>
                <li>
                  <a href="#section-6">Synergy</a>
                </li>
                <li>
                  <a href="#section-7">Playstyles & Teams</a>
                </li>
                <li>
                  <a href="#section-8">What's Next?</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SRGuidesRappa;

export const Head: React.FC = () => (
  <Seo
    title="Castorice - First Impressions | Honkai: Star Rail | Prydwen Institute"
    description="Check the early access first impressions of Castorice - a new character coming soon to Honkai: Star Rail!"
  />
);
